import "jquery-inview";
import { TweenMax } from "gsap/TweenMax";
// import parallax_m from './parallax-m';


// $('.fade-item').on('inview', function(event, isInView) {
//   if (isInView) {
//     $('.fade-item').addClass("is-active");
//     // element is now visible in the viewport
//   } else {
//     $('.fade-item').removeClass("is-active");
//     // element has gone out of viewport
//   }
// });

import ScrollMagic from "scrollmagic";
// import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';



var controller = new ScrollMagic.Controller();




if ($(".l-groupLink-bannerList").length) {
  var scene_groupLink_2 = new ScrollMagic.Scene({
    triggerElement: ".l-groupLink-bannerList",
    triggerHook: 'onEnter',
    // duration: 800,
    offset: 0
  })
    // .addIndicators()
    .addTo(controller);

  scene_groupLink_2.on('enter', () => {
    TweenMax.staggerTo('.l-groupLink-bannerList__item', 1, { y: "0px", alpha: 1 }, 0.3);
  });
}

if ($(".l-reservation-content").length) {
  var scene_reservation_1 = new ScrollMagic.Scene({
    triggerElement: ".l-reservation-content",
    triggerHook: 'onEnter',
    // duration: 800,
    offset: 0
  })
    // .addIndicators()
    .addTo(controller);

  scene_reservation_1.on('enter', () => {
    TweenMax.staggerTo('.l-reservation-button__item', 0.8, { x: "0px", alpha: 1 }, 0.3);
  });
}







// var scene_contents_header = new ScrollMagic.Scene({
//   triggerElement: "body",
//   triggerHook: 'onLeave',
//   // duration: 800,
//   offset: 0
// })
//   // .addIndicators()
//   .addTo(controller);
// scene_contents_header.on('enter', () => {
//   var props2 = $("#luxy").css("transform");
//   var transform3dScaleY = transform3d_value(props2);
//   var offset = scene_contents_header.scrollOffset() > transform3dScaleY.scaleY * -1 ? transform3dScaleY.scaleY * -1 : scene_contents_header.scrollOffset();
//   parallax_m('.l-contentsHeader__background img', offset, 2, 250);
// });

// TweenMax.staggerTo('.top-hero-sub__text p', 1, {y: "0px", alpha: 1, delay: 2}, 0.3);
// var
// var scene2 = new ScrollMagic.Scene({
// 					triggerElement: ".s1" ,
//           triggerHook: 'onEnter',
//           // duration: 800,
// 					offset :0
// 				})
// 				// .setTween(".top-spa", 2, {opacity:0})  // #block1の要素に到達した際に、対象要素のopacityを2秒かけて0にします
// 															 // TweenMaxの関数、toと同様の指定が可能です。
				// .addIndicators()
// 				.addTo(controller);
//
//         scene2.on('enter', ()=>{
//           // parallax_m('.top-bg2__bg img', scene2.scrollOffset(), 100);
//           console.log(scene2.scrollOffset());
//           // TweenMax.to('.top-spa', 2, {opacity:0});
//         });
//         scene2.on('leave', ()=>{
//           TweenMax.to('.top-spa', 2, {opacity:1});
//         });

// let $section = document.querySelectorAll('.s1');
// console.log($section);
//
// for(let i = 0; i < $section.length; i++) {
//   let scene2 = new ScrollMagic.Scene({
//     triggerElement: $section[i],
//     triggerHook: 'onEnter',
//     // reverse: false,
//     offset: 100
//   })
  // .addIndicators()
//   .addTo(controller);
//   scene2.on('enter', ()=>{
//     console.log(scene2.scrollOffset());
//     parallax_m($($section[i]), scene2.scrollOffset(), 200);
//     // TweenMax.to($($section[i]), 2, {opacity:0});
//   });
//   scene2.on('leave', ()=>{
//     TweenMax.to($($section[i]), 2, {opacity:1});
//   });
// }
