jQuery.fn.hasClasses = function(selector, orFlag) {
    var i, len, hitCount = 0;

    if (typeof selector === 'string') {
        selector = (selector.match(/^\./)) ? selector.replace(/^\./, '').split('.') : jQuery.trim(selector).split(' ');
    }

    for (i = 0, len = selector.length; i < len; i++) {
        if (this.hasClass(selector[i])) {
            hitCount++;
        }
    }

    if (orFlag) {
        if (hitCount > 0) return true;
    } else {
        if (hitCount === len) return true;
    }

    return false;
};
