//グローバルナビカレント
var pathname = function () {
  return location.pathname == '/index.html' ? '/' : location.pathname
}

$('.l-header-function-main__navList .l-header-function-main__navItem a').each(function(){

  var $href = $(this).attr('href');
  // console.log($href);

  if(location.href.match($href) && location.pathname != "/" && $href != "/") {
    $(this).closest('.l-header-function-main__navItem').addClass('is-current');
  } else {
    $(this).closest('.l-header-function-main__navItem').removeClass('is-current');
  }

  if(pathname() == "/") {
    // $('.l-header-function-main__navItem:eq(0)').addClass('is-current');
  }

});