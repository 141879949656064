import "jquery-inview";
import { TweenMax, Power2, TimelineLite } from "gsap/TweenMax";

import ScrollMagic from "scrollmagic";
// import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';


var tl_hero = new TimelineMax();
tl_hero.to('.top-hero-main__content', 1, { y: "0px", alpha: 1 }, '+=1')
  .to('.top-hero-sub__movie', 1, { y: "0px", alpha: 1 }, '-=0.3')
  .staggerTo('.top-hero-sub__text p', 1, { y: "0px", alpha: 1 }, 0.3, '-=0.3')
  .staggerTo('.top-hero-news__item', 1, { y: "0px", alpha: 1 }, 0.3, '-=0.3')
  .to('.top-hero-news__more', 1, { y: "0px", alpha: 1 }, '-=0.5')
  .to('.top-hero-exLink', 1, { y: "0px", alpha: 1 }, '-=0.5')
  .to('.top-spa__image', 1, { y: "0px", alpha: 1 }, '-=0.5')
  .to('.top-spa__button', 1, { y: "0px", alpha: 1 }, '-=0.5');



var controller = new ScrollMagic.Controller();

// console.log(document.querySelectorAll(".m-parallax"));




if ($(".top-spa__image").length) {

  var scene_room_3 = new ScrollMagic.Scene({
    triggerElement: ".top-room-content",
    triggerHook: 'onEnter',
    // duration: 800,
    offset: 0
  })
    // .addIndicators()
    .addTo(controller);

  scene_room_3.on('enter', () => {
    var tl = new TimelineMax();

    tl.to(".top-room-content", 1.2, { y: "0px", alpha: 1, ease: Power1.easeOut })
      .to('.top-room-content__title', 1, { y: "0px", alpha: 1 }, '-=0.3')
      .to('.top-room-content__copy', 1, { y: "0px", alpha: 1 }, '-=1.5')
      .to('.top-room-content__text', 1, { y: "0px", alpha: 1 }, '-=0.5')
      .to('.top-room-content__button', 1, { y: "0px", alpha: 1 }, '-=0.5');

    TweenMax.to('.top-room-content__image img', 1.7, { y: "0px", alpha: 1, delay: 1.3 })
  });






  var scene_dish_2 = new ScrollMagic.Scene({
    triggerElement: ".top-dish-content__title",
    triggerHook: 'onEnter',
    // duration: 800,
    offset: 0
  })
    // .addIndicators()
    .addTo(controller);

  scene_dish_2.on('enter', () => {
    var tl = new TimelineMax();
    tl.to('.top-dish-content__title', 1, { y: "0px", alpha: 1 }, '+=0.5')
      .to('.top-dish-content__text', 1, { y: "0px", alpha: 1 }, '-=0.3')
      .to('.top-dish-content__button', 1, { y: "0px", alpha: 1 }, '-=0.3');
  });



  var scene_plan_1 = new ScrollMagic.Scene({
    triggerElement: ".top-plan-content",
    triggerHook: 'onEnter',
    // duration: 800,
    offset: 0
  })
    // .addIndicators()
    .addTo(controller);

  scene_plan_1.on('enter', () => {
    var tl = new TimelineMax();
    tl.staggerTo('.c-planList-card__item', 1, { y: "0px", alpha: 1 }, 0.3)
      .to('.top-plan__button', 1, { y: "0px", alpha: 1 }, '-=0.3');
  });



  // var scene_search_1 = new ScrollMagic.Scene({
  //   triggerElement: ".top-search" ,
  //   triggerHook: 'onEnter',
  //   // duration: 800,
  //   offset :0
  // })
  // .addIndicators()
  // .addTo(controller);

  // scene_search_1.on('enter', ()=>{
  //   // console.log(scene_search_1.scrollOffset())
  //   // console.log($("#luxy").css("transform"))
  //   var props2 = $("#luxy").css("transform");
  //   var transform3dScaleY = transform3d_value(props2);
  //   var offset = scene_search_1.scrollOffset() > transform3dScaleY.scaleY * -1 ? transform3dScaleY.scaleY * -1 : scene_search_1.scrollOffset();
  //   // console.log(transform3dScaleY.scaleY * -1);
  //   // $("#luxy").attr(transform)
  //   parallax_m('.top-search__background img', offset, 7, 250);
  // });





}


if ($(".portal-detail-section").length) {
  let selectors = document.querySelectorAll(".portal-detail-section");
  $(".portal-detail-section").each(function (i, v) { 
    let that = $(this);
    var portalDetail1 = new ScrollMagic.Scene({
      triggerElement: selectors[i],
      triggerHook: 'onCenter',
      // duration: 800,
      offset: -300
    })
      // .addIndicators()
      .addTo(controller);
    
    portalDetail1.on('enter', () => {
      var tl = new TimelineMax();
      tl.to(that.find(".portal-detail-section-content"), 1, {alpha: 1 }, '+=0.5')
      .to(that.find(".portal-detail-section-image"), 1.5, {alpha: 1 }, '-=0.2');
      // .to(that.find(".portal-detail-section-image__sub"), 1, {alpha: 1 }, '-=0.3');
    });
  })
}
