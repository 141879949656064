// import $ from 'jquery';
import './modules/common/svg4everybody.js';
import './modules/common/object-fit-images.js';
import './plugins/jquery_jscrollpane.min.js';
import './modules/common/sp-menu.js';
// import './modules/common/lazyload.js';
import './modules/common/userAgent.js';
import './modules/common/smoothScroll.js';
import './modules/common/tel.js';
import './modules/common/datepicker.js';
import './modules/common/inertiaScroll.js';
import './modules/common/video-player.js';
import './modules/common/parallax.js';
// import './modules/common/loader.js';
import './modules/common/common-animetion.js';
import './modules/common/tablefix.js';
import './modules/common/is-scrolled.js';
import './modules/common/jqueryFitimg.js';
// import './modules/common/roomSearch.js';
import './modules/common/navi-current.js';
// import './modules/top/sp-header-display.js';
import './modules/portal/portal-animation.js';
import './modules/portal/portal-hero.js';
// import './modules/room/room.swiper.js';
// import './modules/spa/spa-slide-content.js';
// import './modules/access/map-app-open.js';
import './modules/recruit/swiper-imageSlider.js';
import './modules/recruit/scrollAnimation.js';

















(function(d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s); js.id = id;
  js.src = 'https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v3.2';
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));


// import lottie from 'lottie-web';
//
// const anim = lottie.loadAnimation({
//   container: document.getElementById('sample'), // the dom element that will contain the animation
//   renderer: 'svg',
//   loop: false,
//   autoplay: false,
//   path: 'data.json' // the path to the animation json
// });
//
// $('.top-hero').on('click', function(){
//
//   anim.play();
// })

// const hoge = val => val + 1;
// console.log(hoge(1));
