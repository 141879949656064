import '../../plugins/jquery.fitimg.js';
$(".top-search__background, .l-groupLink__background").fitimg({
  mode: "cover",
  valign: 'bottom',
  img: {css: {opacity: 1}}
});
$(".l-contentsHeader__background").fitimg({
  mode: "cover",
  valign: 'bottom',
  img: {css: {opacity: 1}}
});