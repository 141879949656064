import '../../plugins/jquery.tablefix_1.0.1.js';

var isMobile = function(){
  return $(window).width() <= 768 ? true : false
}

$(window).on("resize", function(){
  if(!$(".js-tablefix").length && isMobile()) {
    $('.js-fixedTable').tablefix({width: $(".js-fixedTable-wrap").width(), fixCols: 1});
  } else if(!isMobile()) {
    $(".js-tablefix").contents().unwrap();
    $(".js-tablefix-data").remove();
  }
}).trigger("resize");
