import isMobile from '../common/is-mobile.js';


//スマホで画面の高さ取得・設定
$(window).on('resize load', function () {
  if (isMobile(834)) {
    $('.portal-hero').css({
      'height': $(window).height()
    });
    setTimeout(() => {
      $('.portal-hero').css({
        'height': $(window).height()
      });
    }, 500);
  } else {
    $('.portal-hero').removeAttr('style');
  }
}).trigger('resize');

// resizeイベントの取得
// window.addEventListener('resize', () => {
//   if (isMobile(778)) {
//     // あとは上記と同じスクリプトを実行
//     let vh = window.innerHeight * 0.01;
//     document.documentElement.style.setProperty('--vh', `${vh}px`);
//   }
// });