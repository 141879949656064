/*

   / _(_) |_(_)_ __ ___   __ _
  | |_| | __| | '_ ` _ \ / _` |
  |  _| | |_| | | | | | | (_| |
  |_| |_|\__|_|_| |_| |_|\__, |
                          |___/
  v2.0.1

 */
 (function($){

  $.fn.fitimg = function(config){

    var defaults = {
      mode: 'contain',
      box: '',
      halign: 'center',
      valign: 'middle',
      img: {
        css: {},
        load: function () {}
      },
      responsive: [
        {
          breakpoint: '',
          settings: {}
        }
      ]
    }

    var options = $.extend({}, defaults, config);
    var that = this;

    var modes = ['fill', 'contain', 'cover', 'none'];

    var haligns = {'left': 0, 'center': 2, 'right' : 1};
    var valigns = {'top': 0, 'middle': 2, 'bottom' : 1};

    var calculateRatios = function (img, $box) {
        var wRatio = $box.width() / img.width;
        var hRatio = $box.height() / img.height;

        if($.inArray(options.mode, modes) < 0) {
          options.mode = defaults.mode;
        }

        if(options.mode == 'fill') {
          return {wRatio: wRatio, hRatio: hRatio};
        }

        if(options.mode == 'contain') {
          ratio = Math.min(wRatio, hRatio);
        }
        else if(options.mode == 'cover'){
          ratio = Math.max(wRatio, hRatio);
        }
        else if(options.mode == 'none') {
          ratio = wRatio = hRatio = 1;
        }

        return {wRatio: ratio, hRatio: ratio};
    }

    $(window).on('resize', function () {
      that.each(function(){
        var _options;
        $.each(options.responsive, function (i, v) {
          if($(window).width() < v.breakpoint) {
            _options = v.settings
          }
          else {
            _options = options;
          }
        });

        var $_img = $('<img>');
        var $img = $(this).find('img');

        var $box = _options.box ? $img.closest(_options.box) : $(this);

        $box.css({'position' : 'relative', 'overflow' : 'hidden'});

        var ratio = 1;
        $_img.on('load', function () {
          var img = new Image()
          img.src = $(this).attr('src');

          var ratios = calculateRatios(img, $box);

          $img
            .css(_options.img && _options.img.css || {})
            .width(img.width * ratios.wRatio)
            .height(img.height * ratios.hRatio)
            .css('position', 'absolute')
            .css('left', function () {
              var h = haligns[_options.halign] != undefined ? haligns[_options.halign] : haligns[defaults.halign]
              return ($box.width() / h) - ($img.width() / h);
            })
            .css('top', function () {
              var v = valigns[_options.valign] != undefined ? valigns[_options.valign] : valigns[defaults.valign]
              return ($box.height() / v) - ($img.height() / v);
            });

            if(_options.img.load) {
              _options.img.load($img);
            }

        }).attr('src', $img.attr('src'));
      });

    }).resize();

    return that;
  };
})(jQuery);