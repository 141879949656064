import '../../plugins/jquery.hasClasses.js';


$(window).scroll(function() {
  if($(window).scrollTop() >= 1) {

    $('body').addClass("is-scrolled");
    // console.log($(window).scrollTop());
  } else {
    setTimeout(function() {
      // if(!$('body').hasClass('is-open')) {
      //   $('body').removeClass("is-scrolled");
      // }
      if(!$('body').hasClasses(['is-open', 'is-searchContent--showPage'], true)) {
        $('body').removeClass("is-scrolled");
      }

    }, 100);
    $('body').removeClass("is-close");
  }
});

setTimeout(function() {
 // $('.room-listTable__content').closest('.aaa').jScrollPane();
}, 1000);