import { TweenMax } from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
// import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';


var controller = new ScrollMagic.Controller();



let targets = document.querySelectorAll('.recruit-message-section')

for (let target of targets) {

  var scene_hero = new ScrollMagic.Scene({
    triggerElement: target,
    triggerHook: 'onCenter',
    // duration: 800,
    offset: '-100px'
  })
    // .addIndicators()
    .addTo(controller);

  scene_hero.on('enter', () => {
    var tl = new TimelineMax();

    tl
      .to(target.querySelector('.recruit-message-section-image'), 1, { y: "0px", alpha: 1, ease: Sine.easeOut })
      .to(target.querySelector('.recruit-message-section-content'), 1, { y: "0px", alpha: 1, ease: Sine.easeOut, delay: -0.2 });

    // target.classList.add('is-animated');
  });

}


