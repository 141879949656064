import swiper from 'swiper';

$('.js-imageSlider').each(function (i, v) {
  var $that = $(this);

  let slide = $that.find(".swiper-slide").length;
  // $that.find(".swiper-slide").length;

  if (slide > 1) {

    var slider = "";
    var initSwiper = function() {
      slider = new swiper($that, {
        speed: 10000,
        slidesPerView: 'auto',
        spaceBetween: 0,
        centeredSlides: true,
        disableOnInteraction: false,
        // init: false,
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          reverseDirection: false
        },
        loop: true,
      });
    }

    initSwiper();
    window.addEventListener('resize',initSwiper);
    // $(window).on('resize', function() {
    //   topExhibitionList.init()
    // })
  }
});



import isMobile from '../common/is-mobile.js';
// $(window).on('resize', function(){ 
//   if(isMobile(834)) {
//     $('.recruit-hero').css({
//       'height': $(window).height()
//     });
//   } else {
//     $('.recruit-hero').removeAttr('style');
//   }
// }).trigger('resize');